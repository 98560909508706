import React, { useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import FAQs from "../components/Repeating/FAQs";
import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import Badges from "../components/Repeating/Badges";
import Testimonials from "../components/Repeating/Testimonials";
import FindYourCPA from "../components/Repeating/FindYourCPA";
import Locations from "../components/Repeating/Locations";
import Subscribe from "../components/Repeating/Subscribe";
import RecentPosts from "../components/Repeating/RecentPosts";
import CallToAction from "../components/Repeating/CTA";
import ModalVideo from "../components/Modal/ModalVideo";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonWithIcon from "../components/Button/ButtonWithIcon";

const Page = ({ data }) => {
    
  return (
    <Layout>
      <SearchEngineOptimization
        title="Accounting In Cannabis Industry | Dark Horse CPA"
        description="Accounting in the cannabis industry has never been easier! Experience high quality service and planning that your business deserves at Dark Horse today!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

<section className="mb-20 pt-2 md:mb-32 md:pt-16">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>
              Cannabis Industry Accounting
              </h1>
              <p>
              Our mission is to holistically serve your cannabis business. Not just taxes. Not just bookkeeping. Not just fractional CFO. We know your business will be best supported when these service areas come together cohesively.
              </p>
              <ButtonSolid href="/pricing-tool/" text="Get a Quote" />
            </div>
            <div className="relative order-1 md:order-2">
              <GatsbyImage image={data.hero.childImageSharp.gatsbyImageData} />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 pt-2 md:mb-32 md:pt-16">
        <div className="container">
            <h2 className="text-center max-w-[727px] mx-auto">Unique Challenges Require Unique Solutions</h2>
            <p className="text-center max-w-[727px] mx-auto">Businesses in the cannabis industry start out at a disadvantage. Whether you're fighting against tax laws, lack of transparency, or the "green tax," there are many more obstacles for a cannabis business than your “average” business. Dark Horse Cannabis CPAs are industry experts in accounting and tax. We'll help remove those obstacles and gain advantages.</p>
        </div>
        </section>

      

      <section className="mb-20 md:mb-20">
        <div className="container">
        <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h3>Maximizing deductions under 280E by accurately tracking employee hours</h3>
              <h4 className="text-lg uppercase">THE CHALLENGE</h4>
              <p>
              For organizations whose employees perform multiple tasks in the business, being able to accurately track and allocate the employee’s time to inventoriable activities allows the business to capture the employees’ costs in inventory, therefore allowing a larger deduction for calculating cost of goods sold when preparing the company’s tax returns.
              </p>
              
              <h4 className="text-lg uppercase">HOW DARK HORSE CAN HELP</h4>
              <p>
              Dark Horse CPAs will work with you and your management team to ensure accurate tracking of employees’ working hours associated with inventory activities. We will help develop SOPs for capturing employee time accurately and will recommend software-based solutions to reduce the administrative burden.
              </p>
              
                
                <ButtonSolid href="/pricing-tool/" text="Get a Quote" />
              </div>
              <div className="relative order-1 md:order-2">
              <GatsbyImage image={data.intro.childImageSharp.gatsbyImageData} />
            </div>
            
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-20 bg-green-800 text-white py-20">
        <div className="container">
        <h3 className="text-white text-left max-w-[1000px] mb-10">Would you feel confident going into an IRS audit today? What kind of support do you have for your cost of goods sold deductions?</h3>
        <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-2">
              
              <h4 className="text-lg uppercase text-white">THE CHALLENGE</h4>
              <p>
              The U.S. Treasury Inspector General for Tax Administration (TIGTA) issued a report in 2020 encouraging the IRS to increase its efforts in auditing cannabis businesses, suggesting the IRS could collect significantly more revenue from auditing a cannabis business vs. another type of business. The reason TIGTA made this recommendation is that they realized many cannabis businesses 1) are not applying 280E and IRS section 471 correctly, and 2) when they are applied correctly, businesses have insufficient support for their deduction positions.
              </p>
              
              <h4 className="text-lg uppercase text-white">HOW DARK HORSE CAN HELP</h4>
              <p>
              At Dark Horse CPAs, we ensure your tax positions are defensible. We maintain digital copies, receipts and invoices, so they are readily accessible should the IRS come calling. And every position we take on your tax returns is rooted in IRS laws and regulations. So, you can sleep easily at night knowing when Big Brother comes calling, your returns are rock solid.
              </p>
              
                
                <ButtonSolid href="/pricing-tool/" text="Get a Quote" />
              </div>
              <div className="relative order-1 md:order-1">
              <GatsbyImage image={data.audit.childImageSharp.gatsbyImageData} />
            </div>
            
          </div>
        </div>
      </section>



      <section className="mb-20 md:mb-20">
        <div className="container">
        <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h3>Vertically integrated cannabis businesses may hold multiple licenses under the same entity</h3>
              <h4 className="text-lg uppercase">THE CHALLENGE</h4>
              <p>
              It’s imperative that income and expenses under different license types are properly accounted for. Different license types have different tax rules for how to account for and deduct expenses for tax purposes. Additionally, maintaining separate financials for separate licenses will allow you to see how each area of your business is performing.
              </p>
              
              <h4 className="text-lg uppercase">HOW DARK HORSE CAN HELP</h4>
              <p>
              We separate different licenses and activities by divisions in your financials so you can easily identify how the different divisions are operating at any time.
              </p>
              
                
                <ButtonSolid href="/pricing-tool/" text="Get a Quote" />
              </div>
              <div className="relative order-1 md:order-2">
              <GatsbyImage image={data.vertical.childImageSharp.gatsbyImageData} />
            </div>
            
          </div>
        </div>
      </section>
      
      <Testimonials category="cannabis" />

          <section className="mb-24 pt-0 md:mb-48">
        <div className="container">
        <header className="mb-10 text-left md:mb-18">
        <h2 className="max-w-[720px]">Full Spectrum Accounting, Tax, and Advisory Support</h2>
        <p className="max-w-[640px]">Our services include tax planning and prep, full-service bookkeeping, and Fractional CFO services to help you make the best financial decisions for your business.</p>
        </header>
          <div className="grid gap-y-10 md:grid-cols-3 md:gap-x-5 lg:gap-x-10">
            <div>
              <AniLink
                fade
                to="/cannabis-taxes/"
                className="group font-normal text-gray-700 no-underline hover:text-gray-700"
              >
                <div className="mb-5 overflow-hidden rounded-4xl">
                  <GatsbyImage
                    image={data.taxes.childImageSharp.gatsbyImageData}
                    className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3>Maximize Tax Savings</h3>
                <div className="mb-0">
                Taxes can be a buzz kill. Cannabis companies are hamstrung by IRS Section 280E, but we implement tax strategies to minimize your taxes and save you money.
                </div>
              </AniLink>
            </div>
            <div>
              <AniLink
                fade
                to="/cannabis-bookkeeping/"
                className="group font-normal text-gray-700 no-underline hover:text-gray-700"
              >
                <div className="mb-5 overflow-hidden rounded-4xl">
                <GatsbyImage
                    image={data.keeping.childImageSharp.gatsbyImageData}
                    className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3>Expert Bookkeeping</h3>
                <div className="mb-0">
                Your company’s bookkeeping is the basis for all financial decisions in your business. Our expert bookkeeping services are the bedrock of our engagements.
                </div>
              </AniLink>
            </div>
            <div>
              <AniLink
                fade
                to="/cannabis-cfo/"
                className="group font-normal text-gray-700 no-underline hover:text-gray-700"
              >
                <div className="mb-5 overflow-hidden rounded-4xl">
                  <GatsbyImage
                    image={data.cfo.childImageSharp.gatsbyImageData}
                    className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3>Next Level CFO</h3>
                <div className="mb-0">
                The best financials in the world are just numbers on a page if you can’t interpret what those numbers mean. Our CFO advisory can take your business to the next level.
                </div>
              </AniLink>
            </div>
          </div>
        </div>
      </section>

   

<Locations />





      
    
      

      
      <section className="pt-20 pb-20 md:pb-32">
      <div className="container">
        <div className="grid items-center gap-y-8 md:grid-cols-12 md:gap-x-10 lg:gap-x-20">
        <div className="md:col-span-8 md:col-start-1">
        <GatsbyImage image={data.whyus.childImageSharp.gatsbyImageData} className="rounded-2xl"/>

          </div>
        <div className="md:col-span-4 md:col-end-13">
            <h2>Why Dark Horse?</h2>
            <p>
            We work in the industry because we are passionate about the industry. We want to see your Canna-Business succeed, and we'll do everything we can to help you along the way.
            </p>
            <ButtonWithIcon
              href="/about-us/"
              text="Meet Our People"
              altStyle={1}
            />
          </div>
          
          
        </div>
      </div>
    </section>
    
      <CallToAction />
      
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    business: file(relativePath: { eq: "home/business.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 528)
      }
    }
    hero: file(relativePath: { eq: "home/1.0 Cannabis Hero.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    head: file(relativePath: { eq: "home/horse-head.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 55)
      }
    }
    challenges: file(relativePath: { eq: "home/Challenges.jpg" }) {
      childImageSharp {
      gatsbyImageData(layout: CONSTRAINED, width: 760)
      }
    }
    intro: file(
      relativePath: { eq: "home/2.0 Cannabis Intro.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 616)
      }
    }
    audit: file(
        relativePath: { eq: "home/3.0 IRS Audit.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 616)
        }
      }
      vertical: file(
        relativePath: { eq: "home/Vertically Integrated.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 616)
        }
      }
    two: file(
      relativePath: { eq: "home/IRS Section 280E.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 616)
      }
    }
    whyus: file(
      relativePath: { eq: "home/Why Dark Horse_.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 768)
      }
    }
    cultivators: file(
      relativePath: { eq: "home/Cultivators & Manufacturers.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 768)
      }
    }
    dispensaries: file(
      relativePath: { eq: "home/Dispensaries.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 768)
      }
    }
    industry: file(
      relativePath: { eq: "home/Overall Cannabis Industry.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 768)
      }
    }
    taxes: file(relativePath: { eq: "Business Solutions/Tax.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 746)
      }
    }
    keeping: file(relativePath: { eq: "Business Solutions/Bookkeeping-img.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 746)
      }
    }
    cfo: file(relativePath: { eq: "Business Solutions/CFO-img.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 746)
      }
    }
    personal: file(relativePath: { eq: "locations/personal.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 528)
      }
    }
    agriculture: file(relativePath: { eq: "home/Agriculture.svg" }) {
      publicURL
    }
    biotech: file(relativePath: { eq: "home/Bio-Tech.svg" }) {
      publicURL
    }
    cannabis: file(relativePath: { eq: "home/Cannabis.svg" }) {
      publicURL
    }
    construction: file(relativePath: { eq: "home/Construction.svg" }) {
      publicURL
    }
    eCommerce: file(relativePath: { eq: "home/eCommerce.svg" }) {
      publicURL
    }
    contentCreators: file(
      relativePath: { eq: "home/Governmental & Content Creators.svg" }
    ) {
      publicURL
    }
    governmental: file(relativePath: { eq: "home/Governmental.svg" }) {
      publicURL
    }
    manufacturingDistribution: file(
      relativePath: { eq: "home/Manufacturing & Distribution.svg" }
    ) {
      publicURL
    }
    medicalDental: file(relativePath: { eq: "home/Medical & Dental.svg" }) {
      publicURL
    }
    nonProfits: file(relativePath: { eq: "home/Non-Profits.svg" }) {
      publicURL
    }
    professionalServices: file(
      relativePath: { eq: "home/Professional Services.svg" }
    ) {
      publicURL
    }
    realEstate: file(relativePath: { eq: "home/Real Estate.svg" }) {
      publicURL
    }
    retail: file(relativePath: { eq: "home/Retail.svg" }) {
      publicURL
    }
    technology: file(relativePath: { eq: "home/Technology.svg" }) {
      publicURL
    }
    exchange: file(relativePath: { eq: "home/1031 Exchange.svg" }) {
      publicURL
    }
    stockCompensation: file(
      relativePath: { eq: "home/Stock Compensation.svg" }
    ) {
      publicURL
    }
    estateTrustPlanning: file(
      relativePath: { eq: "home/Estate & Trust Planning.svg" }
    ) {
      publicURL
    }
    cryptocurrencyNFTs: file(
      relativePath: { eq: "home/Cryptocurrency & NFTs.svg" }
    ) {
      publicURL
    }
    rentalProperties: file(relativePath: { eq: "home/Rental Properties.svg" }) {
      publicURL
    }
    foreignTax: file(relativePath: { eq: "home/Foreign Tax.svg" }) {
      publicURL
    }
    military: file(relativePath: { eq: "home/Military.svg" }) {
      publicURL
    }
    multiStateFilings: file(
      relativePath: { eq: "home/Multi-State Filings.svg" }
    ) {
      publicURL
    }
    orangeSquare: file(
      relativePath: { eq: "global/decorative-orange-square.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 160)
      }
    }
    orangeCrosses: file(
      relativePath: { eq: "global/decorative-orange-crosses.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 192)
      }
    }
    blueSquare: file(
      relativePath: { eq: "global/decorative-blue-square.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 160)
      }
    }
    blueCrosses: file(
      relativePath: { eq: "global/decorative-blue-crosses.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 192)
      }
    }
  }
`;

export default Page;
